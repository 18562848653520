import Tournament from './Tournament'
import Game from './Game'

class League extends Tournament {
    /**
     * 
     * @param {string} leagueName The name of the league, eventually becomes the tournament name.
     * @param {string} city 
     * @param {string} stateOrCountry 
     * @param {number} numberOfTables 
     * @param {string} startDate Submitted in ISOString Format
     * @param {*} endDate Can be start Date
     * @param {*} creatorID 
     * @param {*} firstHalfOfEmail 
     * @param {boolean} isTeamTournament 
     * @param {string} TeamAName 
     * @param {*} TeamBName 
     * @param {number} gamesToWin 
     * @param {*} isInternationalTournament 
     */
    constructor(leagueName, startDate,TeamAName, TeamBName, GamesToWin,  teamAId,teamBId, seasonid, divisionid, streamkey, streamURL ){
    let newEndDate = new Date(startDate)
        newEndDate.setHours(23,59,0)
        
        
        //Init the Tournament class settings
        super(leagueName,  startDate, )
        //League has to be Team
        this.startDate = startDate
        this.isTeamTournament = true; 
        // League game will add Team Scores to scoreboard
        this.streamKey= streamkey
        this.publicStreamURL = streamURL
        this.isLeagueGame = true; 
         //Need to set End Date to the End of the same day
        this.seasonid = seasonid
        this.divisionid = divisionid
         this.TeamAWins = 0
         this.TeamBWins = 0
        this.GamesToWin = GamesToWin
        this.TeamAName = TeamAName
        this.TeamBName = TeamBName
        this.TeamAID = teamAId
        this.TeamBID = teamBId
        this.tables = this.newLeagueTableList(this.numberOfTables, leagueName, TeamAName,TeamBName )
        
    }
     /**
       * This function is only to be called when creating a new tournament. Generates all blank tables.
       * @param {number} numberOfTables The number of tables
       * @returns {Array} List of blank tables
       */
      newLeagueTableList(numberOfTables, tournamentName, TeamAName, TeamBName) {
        var tables ={}
      for (let i = 0; i < numberOfTables; i++) {
          //New instance of the Game
          var newGame =  new Game(tournamentName,"Table "+(i+1).toString(),true)
          newGame.league_Game(TeamAName, TeamBName)
          //Returns the template for a new game
          
          // Needs to be formatted as an object with indexed numbers as keys
          tables[i.toString()] = newGame
      }
      return tables
    }


}
export default League