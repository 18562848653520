/**
 * This class represents a Live TT Scoreboard game, some settings are needed, others can be added 
 * 
 *  @param {*} tournamentName 
     * @param {*} tableName 
     * @param {*} isTeamTournament 
     * @param {*} isInternationalTournament 
     * @param {*} showFlags 
     * @param {*} TeamAName 
     * @param {*} TeamBName 
 */

class Game {
    /**
     * 
    
     */
    constructor(tournamentName, tableName, isTeamTournament, isInternationalTournament = false, showFlags = false, TeamAName = "", TeamBName = "") {
        if (isTeamTournament) {
            this.TeamAName = TeamAName
            this.TeamBName = TeamBName
        }
        if (isInternationalTournament) {
            this.CountryA = ""
            this.CountryB = ""
        }
        this.gameid = this.createUniqueCode() //30 digit unique code
        this.TournamentName = tournamentName // not normally used, but still there
        this.tournamentName = tournamentName; // Normally used over upper cased version
        this.tableName = tableName
        this.isTeamTournament = isTeamTournament;

        this.playerAMatchScore = 0;
        this.playerAScore = 0;
        this.playerBMatchScore = 0;
        this.playerBScore = 0;
        this.PlayerAFirstName = "";
        this.PlayerALastName = "";
        this.PlayerBFirstName = "";
        this.PlayerBLastName = "";
        this.showFlags = showFlags;
        this.isDoubles = false;
        this.PlayerA2FirstName = "";
        this.PlayerA2LastName = "";
        this.PlayerB2FirstName = "";
        this.PlayerB2LastName = "";
        this.ColorA = 'dodgerblue';
        this.ColorB = 'silver';
        this.AScores = [];
        this.BScores = [];
        this.status = "inactive";
        this.switch = false;
        this.competitionRound = "";
        this.AHasTimeOut = true;
        this.BHasTimeOut = true;
        this.ATimeOutActive = false;
        this.BTimeOutActive = false;
        this.showColor = false;
        this.editing = false;
        this.bestOf5or7 = "5"
        this.tableWasEdited = false
        this.scorboardDirectionAway = true;
        this.scoreboardTheme = "default";
        this.currentUmpire = ""
        this.active = false
        this.requiredFields = []

        this.magnify = 1 // for Live Stream settings
    }

 /**
     * Generates a unique code. 
     * @returns {string} Thirty digit code.
     */
    createUniqueCode() {
      
        var charset = "QWERTYUIOPASDFGHJKLZXCVBNM1234567890"
        var scoreboardID = ""
        for (let index = 0; index <= 29; index++) {
         // console.log(index)
    
          var indexchar = Math.floor(Math.random() * 36)
         // console.log(indexchar)
          //console.log(charset[indexchar])
          scoreboardID += charset[indexchar]
    
        }
        //console.log("This scoreboard ID");
        //console.log(scoreboardID);
        return scoreboardID;
      }

    set_Color(ColorA, ColorB){ 
        this.ColorA = ColorA
        this.ColorB = ColorB
    }
    set_scoreboard_direction(directionAway) {
        this.scorboardDirectionAway = directionAway
    }
    add_Table_Name(tableName) {
        this.tableName = tableName;
    }
    set_competition_round(round){
        this.competitionRound = round
    }
    /**
     * @param {object} styles A CSS object.
     */
    add_live_stream_styling(styles) {

    }

    set_best_of(number){
        this.bestOf5or7 = number
    }

   

    league_Game(TeamAName, TeamBName) {
        this.TeamAName = TeamAName
        this.TeamBName = TeamBName
        this.isTeamTournament = true
        this.isLeagueGame = true
        this.competitionRound = "League Match"
    }


}
export default Game