import firebase from "gatsby-plugin-firebase";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Button, Card, Dialog, Switch } from "@material-ui/core";
import { Link } from "gatsby";
import HomeAppBar from "../components/HomeAppBar";
import axios from "axios";
import League from "../classes/League";
 

export default class EditDivision extends React.Component {

    defaultnewMatchInfo = {
        homeTeam: "",
        visitorTeam: "",
        matchDate: new Date(),
        startTimeAMPM: "AM",
        startTimeMinute: "00",
        startTimeHour: "9",
        matchName:"My Test Stream",
        //matchDescription:"Jack's first test of the stream",
        streamKey: "",
        streamURL: "rtmp://x.rtmp.youtube.com/live2/",
    publicStreamURL: "",

    }

    state = {
        showAddTeam: false,
        showAddMatch: false,
        showAddPlayer: false,
        newplayerInfo: {},
        newteamInfo: {},
        newMatchInfo: this.defaultnewMatchInfo,
        teams: [],
        matches: [],
        isActive:false,
        streamkeys:{}




    }



    componentDidMount() {
        const urlSearchParams = new URL(window.location);
        var tournamentId = urlSearchParams.searchParams.get("seasonid")
        var divisionid = urlSearchParams.searchParams.get("divisionid")
        firebase.database().ref("seasons").child(tournamentId).child("divisions").child(divisionid).on("value", (data) => {
            this.setState({ ...data.val(), seasonid: tournamentId, divisionid:divisionid })
            console.log(data.val())
            console.log(divisionid)
            firebase.database().ref("matches").orderByChild("divisionid").equalTo(divisionid).on("value",(data1)=>{
                console.log(data1.val())
                this.setState({matches: data1.val()})
            })
        })
        firebase.database().ref("streamkeys").once("value", (streamkeys) =>{
            this.setState({streamkeys: streamkeys.val()})
            this.defaultnewMatchInfo.streamKey = streamkeys.val()["Main"]
        })
        
    }

    TeamOptions = () => {
        return Object.keys(this.state.teams).map((homeTeamId) => {
            return (
                <option value={homeTeamId}>{this.state.teams[homeTeamId]["name"]}</option>
            )
        })
    }

    styles = {
        table: {
            maxWidth: 500,
            margin: 5
        }
    }
    //http://www.lapingpong.com/results/LeagueData.js
    

    render() {
        console.log(this.state)
        return (
            <div>
                
                <HomeAppBar title={this.state.divisionName}></HomeAppBar>
          
                <Card style={this.styles.table}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <h1>Manage Teams</h1>
                        <Button style={{ float: "left" }} color="primary" onClick={() => {
                            this.setState({ showAddTeam: this.state.showAddTeam ? false : true })
                        }}>Add Team</Button>
                    </div>


                    {
                        console.log(this.state.teams)
                    }

                    <Table>

                    {
                        Object.keys(this.state.teams).map((team) => {
                            return (

                                
                                        <TableRow hover>
                                            <TableCell> {this.state.teams[team].name}
                                            </TableCell> 
                                            <TableCell>
                                                 <Button href={"/manageteams"+window.location.search+"&teamid="+team} onClick={() => {
                                                //this.setState({ showAddPlayer: true, selectedTeamID: team })
                                            }}>Manage Team</Button>
                                            </TableCell>
                                        </TableRow>
                                           
                                           
                                           
                                  

                                   
                                  






                            )
                        })
                    }
            </Table>
                </Card>
                <Card style={this.styles.table}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <h1>Manage Matches</h1>
                        <Button color="primary" onClick={() => {
                            this.setState({ showAddMatch: this.state.showAddMatch ? false : true })
                        }}>Add Match</Button>
                    </div>

                    <Table>
                        <TableHead>
                            <TableRow>

                                <TableCell>Home Team</TableCell>
                                <TableCell>Visitor Team</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Time</TableCell>

                            </TableRow>

                        </TableHead>
                        <TableBody>
                            {   typeof this.state.matches != "undefined" && this.state.matches !=null ?
                                Object.keys(this.state.matches).map((matchId) => {
                                    console.log(this.state.matches[matchId])
                                    console.log(this.state.teams[this.state.matches[matchId].homeTeam])
                                    return (
                                        <TableRow>
                                            <TableCell>{this.state.teams[this.state.matches[matchId].TeamAID].name}</TableCell>
                                            <TableCell>{this.state.teams[this.state.matches[matchId].TeamBID].name}</TableCell>
                                            <TableCell>{this.state.matches[matchId].matchDate}</TableCell>
                                            <TableCell>{new Date(this.state.matches[matchId].startDate).toLocaleString()}</TableCell>
                                            <TableCell><a href={"/editmatch?matchid="+matchId}>Edit</a></TableCell>
                                        </TableRow>
                                    )
                                })
                                :null
                            }
                        </TableBody>
                    </Table>
                </Card>

{
    //  <Card style={this.styles.table}>
    //                         <Table>
    //                             <TableBody>
    //                                 <TableRow>
    //                                     <TableCell>Activate/Deactivate Season</TableCell>
    //                                     <TableCell>
    //                                         <Switch
    //                                         onChange={(ev)=>{
    //                                             this.setState({isActive: this.state.isActive ? false : true}, () =>{
    //                                                 firebase.database().ref("seasons").child(this.state.seasonid).child("divisions").child(this.state.divisionid).update({isActive: ev.target.value})
    //                                             })
                                                
    //                                         }}
    //                                          checked={this.state.isActive}></Switch>
    //                                         </TableCell>
    //                                 </TableRow>
    //                             </TableBody>
    //                         </Table>

    //                         </Card>
}
                           






                {
                    /**
                     * 
                     * DIALOGS START HERE
                     */
                }
                <Dialog open={this.state.showAddTeam}>
                    <Card style={this.styles.table}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Add New Team</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell><input onChange={(ev) => {
                                        this.setState({ newteamInfo: { ...this.state.newteamInfo, name: ev.target.value } })
                                    }} type="text" ></input></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Team Captain</TableCell>
                                    <TableCell><input onChange={(ev) => {
                                        this.setState({ newteamInfo: { ...this.state.newteamInfo, captain: ev.target.value } })
                                    }} type="text"></input></TableCell>
                                </TableRow>
                                <TableRow>

                                    <TableCell><Button onClick={() => {
                                        // console.log(this.state.id)
                                        firebase.database().ref("seasons").child(this.state.seasonid).child("divisions").child(this.state.divisionid).child("teams").push(this.state.newteamInfo)
                                        this.setState({ showAddTeam: false })
                                    }}>Add</Button></TableCell>
                                    <TableCell><Button onClick={() => {
                                        this.setState({ showAddTeam: this.state.showAddTeam ? false : true })
                                    }}>Close</Button></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Card>
                </Dialog>

                <Dialog open={this.state.showAddMatch}>
                    <Card style={this.styles.table}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Add Match</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Home Team</TableCell>
                                    <TableCell>
                                        <select defaultValue="" onChange={(ev) => {
                                            this.setState({ newMatchInfo: { ...this.state.newMatchInfo, homeTeam: ev.target.value } })
                                        }}>

                                            <option value="">Select Team</option>
                                            <this.TeamOptions></this.TeamOptions>
                                        </select>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Visiting Team</TableCell>
                                    <TableCell>
                                        <select onChange={(ev) => {
                                            this.setState({ newMatchInfo: { ...this.state.newMatchInfo, visitorTeam: ev.target.value } })
                                        }}>
                                            <option value="">Select Team</option>
                                            <this.TeamOptions></this.TeamOptions>
                                        </select>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Match Date</TableCell>
                                    <TableCell><input defaultValue={new Date(this.state.newMatchInfo.matchDate).toISOString().slice(0, 10)} onChange={(ev) => {

                                        this.setState({ newMatchInfo: { ...this.state.newMatchInfo, matchDate: ev.target.value } })
                                    }} type="date"></input></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Match Time</TableCell>
                                    <TableCell><select defaultValue={this.state.newMatchInfo.startTimeHour} onChange={(ev) => {

                                        this.setState({ newMatchInfo: { ...this.state.newMatchInfo, startTimeHour: ev.target.value } }, () => { console.log(this.state) })
                                    }}>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                        <option value={6}>6</option>
                                        <option value={7}>7</option>
                                        <option value={8}>8</option>
                                        <option value={9}>9</option>
                                        <option value={10}>10</option>
                                        <option value={11}>11</option>
                                        <option value={12}>12</option>
                                    </select>:
                                        <select defaultValue={this.state.newMatchInfo.startTimeMinute} onChange={(ev) => {

                                            this.setState({ newMatchInfo: { ...this.state.newMatchInfo, startTimeMinute: ev.target.value } }, () => { console.log(this.state) })
                                        }}>
                                            <option value={"00"}>00</option>
                                            <option value={"15"}>15</option>
                                            <option value={"30"}>30</option>
                                            <option value={"45"}>45</option>
                                        </select>
                                        <select defaultValue={this.state.newMatchInfo.startTimeAMPM} onChange={(ev) => {

                                            this.setState({ newMatchInfo: { ...this.state.newMatchInfo, startTimeAMPM: ev.target.value } }, () => { console.log(this.state) })
                                        }}>
                                            <option value="AM">AM</option>
                                            <option value="PM">PM</option>
                                        </select>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Youtube Stream Key</TableCell>
                                    <TableCell>
                                        <select onChange={(ev) => {

this.setState({ newMatchInfo: { ...this.state.newMatchInfo, streamkeys: ev.target.value } }, () => { console.log(this.state) })
}}  >
                                            {Object.keys(this.state.streamkeys).map((key)=>{
                                                return (
                                                    <option value={this.state.streamkeys[key]}>{key}</option>
                                                )
                                            })}
                                        </select>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Youtube Event URL</TableCell>
                                    <TableCell><input onChange={(ev) => {

this.setState({ newMatchInfo: { ...this.state.newMatchInfo, publicStreamURL: ev.target.value } }, () => { console.log(this.state) })
}} type="text"></input></TableCell>
                                </TableRow>

                                <TableRow>

                                    <TableCell><Button
                                        disabled={this.state.newMatchInfo.homeTeam.length < 1 || this.state.newMatchInfo.visitorTeam.length < 1}
                                        onClick={() => {
                                            var startDate = new Date(this.state.newMatchInfo.matchDate)
                                            startDate.setHours(this.state.newMatchInfo.startTimeAMPM == "PM" ? parseInt(this.state.newMatchInfo.startTimeHour)+12:parseInt(this.state.newMatchInfo.startTimeHour))
                                            startDate.setMinutes(parseInt( this.state.newMatchInfo.startTimeMinute))
                                            var newMatch = new League("LA Ping Pong",startDate.toISOString(),this.state.teams[this.state.newMatchInfo.homeTeam].name , this.state.teams[this.state.newMatchInfo.visitorTeam].name,3,this.state.newMatchInfo.homeTeam, this.state.newMatchInfo.visitorTeam,this.state.seasonid,this.state.divisionid, this.state.newMatchInfo.streamKey, this.state.newMatchInfo.publicStreamURL)

                                            firebase.database().ref("matches").push(newMatch)
                                           //  firebase.database().ref("seasons").child(this.state.seasonid).child("divisions").child(this.state.divisionid).child("matches").push(this.state.newMatchInfo)
                                            this.setState({ showAddMatch: false, newMatchInfo: this.defaultnewMatchInfo })
                                        }} >Add</Button></TableCell>
                                    <TableCell><Button onClick={() => {
                                        this.setState({ showAddMatch: this.state.showAddMatch ? false : true })
                                    }}>Close</Button></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Card>
                </Dialog>


                <Dialog open={this.state.showAddPlayer}>
                    <Table>
                        <TableHead>
                            <TableRow><TableCell>Add Player To Team</TableCell></TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Player Name</TableCell>
                                <TableCell><input onChange={(ev) => {
                                    this.setState({ newplayerInfo: { ...this.state.newplayerInfo, name: ev.target.value } })
                                }} type="text"></input></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><Button onClick={() => {
                                    firebase.database().ref("seasons").child(this.state.seasonid).child("divisions").child(this.state.divisionid).child("teams").child(this.state.selectedTeamID).child("players").push(this.state.newplayerInfo)
                                    this.setState({ newplayerInfo: {}, showAddPlayer: false })
                                }}
                                >Save</Button></TableCell>
                                <TableCell><Button onClick={() => this.setState({ showAddPlayer: this.state.showAddPlayer ? false : true })}>Close</Button></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Dialog>

                <Dialog open={this.state.showEditPlayer}>
                    <Table>
                        <TableHead>
                            <TableRow><TableCell>Edit Player</TableCell></TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Player Name</TableCell>
                                <TableCell><input value={this.state.editPlayerName} onChange={(ev) => {
                                    this.setState({ editPlayerName: ev.target.value })
                                }} type="text"></input></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><Button onClick={() => {
                                    firebase.database().ref("seasons").child(this.state.seasonid).child("divisions").child(this.state.divisionid).child("teams").child(this.state.editTeam).child("players").child(this.state.editPlayer).update({name: this.state.editPlayerName})
                                    this.setState({editPlayerName:"", showEditPlayer: false })
                                }}
                                >Save</Button></TableCell>
                                <TableCell><Button onClick={() => this.setState({ showEditPlayer: this.state.showEditPlayer ? false : true })}>Close</Button></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Dialog>





            </div>
        )
    }
}