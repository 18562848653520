import Game from './Game'
//import LiveStreamSettingsClass from './LiveStreamSettings'
//import ScoreboardSettingsClass from './ScoreboardSettings'

class Tournament {
    /**
 * @param {('tournament' | 'league')} eventType 
 * 
 */
    constructor( tournamentName, startDate, ) {
        this.magnify = 1
        this.TournamentName = tournamentName;
       // this.city = city;
       // this.stateOrCountry = stateOrCountry;
        this.numberOfTables = 1;
        this.startDate = startDate;
        //this.endDate = endDate;
        //this.myID = creatorID;
       // this.tournamentCreator = firstHalfOfEmail
        this.isTeamTournament = true
       // this.isInternationalTournament = isInternationalTournament
        this.id = this.createUniqueCode();
        this.password = this.createUniqueCode()
        this.defaultLiveStreamScoreboard = "v1" //Can be v1 or v2
        //this.liveStreamURL = liveStreamURL
     //   this.liveStreamScoreboardID = defaultLiveStreamID || new LiveStreamSettingsClass(this.myID).id
    //    this.scoreboardID = defaultScoreboardID || new ScoreboardSettingsClass(this.myID).id
        this.tables = this.newTournamentTableList(1,tournamentName,true)



       
        


    }
    /**
     * Generates a unique code. 
     * @returns {string} Six digit code.
     */
    createUniqueCode() {
      
        var charset = "QWERTYUIOPASDFGHJKLZXCVBNM1234567890"
        var scoreboardID = ""
        for (let index = 0; index <= 5; index++) {
          console.log(index)
    
          var indexchar = Math.floor(Math.random() * 36)
          console.log(indexchar)
          console.log(charset[indexchar])
          scoreboardID += charset[indexchar]
    
        }
        console.log("This scoreboard ID");
        console.log(scoreboardID);
        return scoreboardID;
      }
      /**
       * This function is only to be called when creating a new tournament. Generates all blank tables.
       * @param {number} numberOfTables The number of tables
       * @returns {Array} List of blank tables
       */
      newTournamentTableList(numberOfTables, tournamentName, isTeamTournament, isInternationalTournament=false) {
          var tables ={}
        for (let i = 0; i < numberOfTables; i++) {
            //New instance of the Game
            var newGame =  new Game(tournamentName,"Table "+(i+1).toString(),isTeamTournament)
            //Returns the template for a new game
            
            // Needs to be formatted as an object with indexed numbers as keys
            tables[i.toString()] = newGame
        }
        return tables
      }

    



//End of class
}

export default Tournament